import { PageRoutes } from "../routes/PageRoutes";
import ReportScreen from "./enums/ReportScreens";

export const toTitleCase = (str: string): string => {
  return str.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
    return match.toUpperCase();
  });
};

export const screenNavigation = (type: string | null) => {
  if (type == ReportScreen.COUPON_PAGE) {
    return PageRoutes.COUPON_PAGE;
  } else if (type == ReportScreen.DOCTOR_PAGE) {
    return PageRoutes.DOCTOR_PAGE;
  } else if (type == ReportScreen.DISCOUNT_APPROVALS_PAGE) {
    return PageRoutes.DISCOUNT_APPROVALS_PAGE;
  } else {
    return PageRoutes.LOGIN;
  }
};

export const showActionItems = (
  appCode: string,
  status: string,
  role: string
) => {
  return role === "ADMIN" && status === "pending";
};
