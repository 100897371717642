import {
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DiscountApprovalsEntitiesTable from "./DiscountApprovalsEntitiesTable";
import { useDiscountApprovalStore } from "../../../../store/main/DiscountApprovalStore";
import { FetchState } from "../../../../domain/models/fetch-state-type";
import DiscountApprovalsActionModal from "../../Common/Modal/DiscountApprovalsActionModal";
import { debounce } from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import ICONS from "../../../../assets";
import { PageRoutes } from "../../../../routes/PageRoutes";
import { useNavigate } from "react-router-dom";

const searchBoxStyle = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
  "& .MuiFormLabel-root": {
    fontSize: "12px",
    color: "primary.light",
  },
  borderRadius: "10px",
  backgroundColor: "white",
  marginBottom: 1,
};

const DiscountApprovalsComponent = () => {
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const {
    fetchEntityListState,
    fetchEntityList,
    entityList,
    openConfirmModal,
    handleConfirmModal,
    updateRequestStatus,
    captureUpdateRequestData,
    entityListCurrentPage,
    entityListLastPage,
    entityListFilters,
    handlePageNumber,
  } = useDiscountApprovalStore();

  const handlePageChange = (event: any, newPage: number) => {
    handlePageNumber(newPage);
    fetchEntityList(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent) => {
    setRowsPerPage(parseInt(event.target.value));
    fetchEntityList(entityListCurrentPage, parseInt(event.target.value));
  };

  const applySearchFilter = (e: any) => {
    entityListFilters.q = e.target.value;
    fetchEntityList(1, 10);
  };

  const debouncedOnChange = debounce(applySearchFilter, 1000);
  const handleUpdateStatusChange = (value: string, action: string) => {
    if (action === "remarks") {
      captureUpdateRequestData.remarks = value;
    } else if (action === "editDiscount") {
      captureUpdateRequestData.selectedDiscount = value;
    } else if (action === "approved_start_date") {
      captureUpdateRequestData.approved_start_date = value;
    } else if (action === "approved_end_date") {
      captureUpdateRequestData.approved_end_date = value;
    }
  };
  const [selected, setSelected] = React.useState("pending");

  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
    newSelection: string | null
  ) => {
    if (newSelection !== null) {
      if (newSelection == "all") {
        entityListFilters.is_approval_required = "";
        entityListFilters.status = "";
      } else if (newSelection == "pending") {
        entityListFilters.is_approval_required = "1";
        entityListFilters.status = "";
      } else {
        entityListFilters.is_approval_required = "";
        entityListFilters.status = newSelection;
      }
      entityListFilters.entity_type = entityValue;
      fetchEntityList(1, 10);

      setSelected(newSelection);
    }
  };
  useEffect(() => {
    entityListFilters.is_approval_required = "1";
    entityListFilters.status = "";
    entityListFilters.entity_type = entityValue;

    fetchEntityList(1, 10);
  }, []);
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(PageRoutes.PRODUCT_SLAB);
  };
  const [entityValue, setEntityValue] = useState("HOSPITAL");

  const handleChange = (event: any) => {
    entityListFilters.entity_type = event.target.value;
    setEntityValue(event.target.value);
    fetchEntityList(1, 10);
  };
  return (
    <>
      <Stack p={2} spacing={3}>
        <Typography variant="h5" fontWeight={500}>
          Discount Approvals
        </Typography>

        <Stack spacing={2}>
          {" "}
          <Stack
            justifyContent={"space-between"}
            alignItems={"center"}
            direction={"row"}
          >
            <TextField
              onChange={debouncedOnChange}
              size="small"
              placeholder="Search Customer..."
              sx={searchBoxStyle}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {<SearchIcon />}
                  </InputAdornment>
                ),
              }}
            />

            <Stack
              spacing={2}
              direction={"row"}
              justifyContent={"end"}
              alignItems={"center"}
            >
              <Stack>
                <Button onClick={handleNavigate}>Product Slab</Button>
              </Stack>
              <Stack
                direction={"row"}
                spacing={1}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Typography variant="caption">Rows per page:</Typography>
                <FormControl>
                  <Select
                    value={rowsPerPage.toString()}
                    variant="standard"
                    size="small"
                    onChange={handleChangeRowsPerPage}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={70}>70</MenuItem>
                    <MenuItem value={90}>90</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <Pagination
                count={entityListLastPage}
                variant="outlined"
                shape="rounded"
                page={entityListCurrentPage}
                onChange={handlePageChange}
              />
            </Stack>
          </Stack>
          <Stack flexDirection={"row"} gap={2}>
            <ToggleButtonGroup
              value={selected}
              exclusive
              onChange={handleToggle}
              aria-label="Variable selection"
            >
              <ToggleButton value="pending" aria-label="pending">
                Pending
              </ToggleButton>
              <ToggleButton value="approved" aria-label="approved">
                Approved
              </ToggleButton>
              <ToggleButton value="all" aria-label="all">
                All
              </ToggleButton>
            </ToggleButtonGroup>

            <TextField
              select
              label="Entity"
              value={entityValue}
              onChange={handleChange}
              variant="outlined"
              sx={{ width: "20ch" }}
              SelectProps={{ displayEmpty: true }}
              InputLabelProps={{ shrink: true }}
            >
              <MenuItem value="">ALL</MenuItem>
              <MenuItem value="DOCTOR">Doctor</MenuItem>
              <MenuItem value="HOSPITAL">NH Pharmacy</MenuItem>
            </TextField>
          </Stack>
          {fetchEntityListState == FetchState.SUCCESS &&
            entityList.length > 0 && (
              <Stack>
                <Typography>No of Entities: {entityList.length} </Typography>
                <DiscountApprovalsEntitiesTable selected={selected} />
              </Stack>
            )}
        </Stack>

        {fetchEntityListState === FetchState.LOADING && (
          <Stack
            width={"100%"}
            height={"60vh"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress size={40} />
          </Stack>
        )}
        {fetchEntityListState == FetchState.SUCCESS &&
          entityList.length == 0 && (
            <Stack alignItems={"center"} justifyContent={"center"}>
              <img src={ICONS.HappyLogo} width={"40%"} />
              <Typography variant="body1" fontWeight={660}>
                No pending approvals! 🚀 Smooth sailing ahead, Captain! ⛵️
              </Typography>
            </Stack>
          )}

        {captureUpdateRequestData && captureUpdateRequestData.requestId && (
          <DiscountApprovalsActionModal
            openModal={openConfirmModal}
            handleCloseModal={handleConfirmModal}
            handleUpdateStatus={updateRequestStatus}
            data={captureUpdateRequestData}
            handleUpdateStatusChange={handleUpdateStatusChange}
          />
        )}
      </Stack>
    </>
  );
};

export default DiscountApprovalsComponent;
